<template>
  <v-col cols="12" md="4" v-if="client">
    <v-card :color="'#' + client.clientColor">
      <v-list-item three-line @click="showClientInfoDialog = true">
        <v-list-item-avatar size="60" color="secondary">
          <v-img :src="clientPhoto(client)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <div>
            <h4>
              <!-- out now -->

              <custom-button
                v-if="clientOutNow"
                type="iconBtn"
                icon="directions_walk"
                color="#FB8C00"
                :toolTipText="$t('client.on-off-day')"
                dynamicClass="blink"
              />
              <!-- recent health problems -->
              <custom-button
                v-if="client.hasRecentHealthProblems"
                type="iconBtn"
                icon="warning"
                color="error"
                :toolTipText="$t('client.health-alert')"
              />

              <custom-button
                v-if="client.clientStatus == 'paused'"
                type="iconBtn"
                icon="pause_circle_outline"
                color="error"
                :toolTipText="$t('client.status-paused-description')"
              />

              <custom-button
                v-if="client.clientStatus == 'stopped'"
                type="iconBtn"
                icon="stop"
                color="error"
                :toolTipText="$t('client.status-stopped-description')"
              />

              <custom-button
                v-if="client.needRepositioning"
                type="iconBtn"
                icon="hotel"
                color="error"
                :toolTipText="$t('client.need-regular-repositioning')"
              />
              <custom-button
                v-if="client.isDiabetic"
                type="iconBtn"
                icon="local_dining"
                color="error"
                :toolTipText="$t('client.diabetes')"
              />
              <custom-button
                v-if="client.haveHeartPacemaker"
                type="iconBtn"
                icon="monitor_heart"
                color="error"
                :toolTipText="$t('client.heart-pacemaker')"
              />
              <custom-button
                v-if="client.haveDentures"
                type="iconBtn"
                icon="plumbing"
                color="error"
                :toolTipText="$t('client.dentures')"
                dynamicClass="pb-1"
              />
              {{ client.clientName }}
            </h4>
          </div>
          <v-list-item-subtitle>
            {{ client.clientDistrictName }}
            <span v-if="client.clientServices == 'bfh'"
              >- {{ client.bfhRoom.roomName }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <!-- <template v-if="client.clientServices == 'neighborhood'">
          <btn-cancel-visit
            :key="'cancel-visit-' + clientId"
            :clientId="clientId"
            :clientData="client"
          ></btn-cancel-visit>
          <v-spacer></v-spacer>
        </template> -->

        <!-- ADD OFFDAY OR RETUN -->
        <add-off-day
          :client="client"
          :clientId="clientId"
          :key="'off-day-btn-' + clientId"
        ></add-off-day>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="showAddHealthDialog = true"
              small
              outlined
              fab
              v-on="on"
            >
              <v-icon>local_hospital</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("client.add-health-record") }}</span>
        </v-tooltip>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="showAddIncidentDialog = true"
              small
              outlined
              fab
              v-on="on"
            >
              <v-icon>error</v-icon>
            </v-btn>
          </template>
          <span> {{ $t("client.add-incident") }} </span>
        </v-tooltip>
        <template v-if="activeHouseCareNewPlan">
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="showPlanTasksDialog = true"
                small
                outlined
                fab
                v-on="on"
              >
                <v-icon>format_list_numbered</v-icon>
              </v-btn>
            </template>
            <span> {{ $t("tasks.tasks") }} </span>
          </v-tooltip>
        </template>
      </v-card-actions>
    </v-card>

    <!-- client info dialog -->
    <client-info-dialog
      :clientInfoDialog="showClientInfoDialog"
      :clientData="client"
      :clientId="clientId"
      :key="'dialog-' + clientId"
      @closeClientInfoDialog="showClientInfoDialog = false"
    ></client-info-dialog>

    <!-- incidents dialog -->
    <add-incident-dialog
      :incDialog="showAddIncidentDialog"
      :clientData="client"
      :clientId="clientId"
      :key="'add-incident-' + clientId"
      @closeClientIncDialog="showAddIncidentDialog = false"
    >
    </add-incident-dialog>

    <!-- health dialog -->
    <add-health-dialog
      :healthDialog="showAddHealthDialog"
      :clientData="client"
      :clientId="clientId"
      :key="'add-health-' + clientId"
      @closeClientHealthDialog="showAddHealthDialog = false"
    />
    <view-house-care-new-plan-tasks-dialog
      :planData="activeHouseCareNewPlan"
      :clientData="client"
      @closeDialog="showPlanTasksDialog = false"
      :dialogOpen="showPlanTasksDialog"
    />
  </v-col>
</template>

<script>
import { db } from "@/main";
import ClientInfoDialog from "@/views/myclients/ClientInfo.vue";
import AddIncidentDialog from "@/views/myclients/AddIncident.vue";
import AddHealthDialog from "@/views/myclients/AddHealth.vue";
import AddOffDay from "@/views/myclients/AddOffDay.vue";
// import BtnCancelVisit from "@/views/myclients/VisitCancel.vue"; //DEACTIVATED SINCE 2021-10-28
import "@/helpers/blink.css";
import CustomButton from "@/components/CustomButton.vue";
import { CustomBtnTypes } from "@/helpers/constants";
import ViewHouseCareNewPlanTasksDialog from "./ViewHouseCareNewPlanTasksDialog.vue";

export default {
  name: "client-card",
  props: ["client", "clientId"],
  components: {
    ClientInfoDialog,
    AddIncidentDialog,
    AddHealthDialog,
    AddOffDay,
    CustomButton,
    ViewHouseCareNewPlanTasksDialog,
  },
  data() {
    return {
      showClientInfoDialog: false,
      showAddIncidentDialog: false,
      showAddHealthDialog: false,
      showPlanTasksDialog: false,
      buttonTypes: CustomBtnTypes,
      activeHouseCareNewPlan: null,
    };
  },
  created() {
    this.getHouseCareNewPlans();
  },
  computed: {
    clientOutNow: function () {
      if (!this.client.latestOffDay) {
        return false;
      }

      return this.client.latestOffDay.outNow;
    },
  },
  methods: {
    clientPhoto(clientData) {
      if (!clientData.clientPhotoUrl || clientData.clientPhotoUrl == "") {
        //   default photo
        return "https://img.icons8.com/windows/2x/contacts.png";
      } else {
        return clientData.clientPhotoUrl;
      }
    },
    async getHouseCareNewPlans() {
      let houseCareNewPlansResponse = await db
        .collection("clients")
        .doc(this.clientId)
        .collection("houseCareNewPlans")
        .get();
      houseCareNewPlansResponse.docs.forEach((doc) => {
        let data = doc.data();
        if (data.active) {
          this.activeHouseCareNewPlan = data;
        }
      });
    },
  },
};
</script>
