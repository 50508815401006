<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <h1>{{ $t("client.my-clients") }}</h1>
      </v-col>
      <v-col cols="4">
        <view-as-user-selection @onUserSelection="onUserSelection" />
      </v-col>
    </v-row>
    <v-col
      cols="12"
      lg="2"
      md="4"
      sm="6"
      v-if="aiderServices.indexOf('neighborhood') > -1"
    >
      <select-date
        :loadedDate="selectedDate"
        @newDate="dateSelected"
        :future="true"
      >
      </select-date>
    </v-col>

    <loading-data
      v-if="loadingData"
      :feedbackText="feedbackText"
    ></loading-data>

    <!-- my reported incidents -->
    <v-row v-if="Object.keys(myReportedIncidents).length > 0">
      <v-col cols="12">
        <v-card>
          <v-card-title> {{ $t("client.todays-incidents") }} </v-card-title>
          <v-card-text>
            <v-list-item
              dense
              v-for="(inc, incId) in myReportedIncidents"
              :key="incId"
            >
              <span>
                <strong>{{ inc.clientName }}</strong> -
                {{ getTranslatedIncident(inc) }}
                {{ inc.dateFormatted }}

                <span v-if="inc.comment">
                  {{ $t("general.comment") }}: {{ inc.comment }}
                </span>
              </span>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- my reported health -->
    <v-row v-if="Object.keys(myReportedHealth).length > 0">
      <v-col cols="12">
        <v-card>
          <v-card-title> {{ $t("client.todays-health-records") }}</v-card-title>
          <v-card-text>
            <v-list-item
              dense
              v-for="(hdata, hId) in myReportedHealth"
              :key="hId"
            >
              <span>
                <strong>{{ hdata.clientName }}</strong> -
                <span v-if="hdata.emergency">
                  {{ $t("client.emergency-health-problems-on").toLowerCase() }}
                </span>
                <span v-else>
                  {{ $t("client.health-records-on").toLowerCase() }}
                </span>
                {{ hdata.dateFormatted }}

                <span v-if="hdata.comment">
                  {{ $t("general.comment") }}: {{ hdata.comment }}
                </span>
              </span>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- my reported off days -->
    <v-row v-if="Object.keys(myReportedOffDays).length > 0">
      <v-col cols="12">
        <v-card>
          <v-card-title> {{ $t("client.todays-outings") }} </v-card-title>
          <v-card-text>
            <v-list-item
              dense
              v-for="(od, odId) in myReportedOffDays"
              :key="odId"
            >
              <span>
                <strong>{{ od.clientName }}</strong>
                <ul>
                  <li>
                    {{ $t("client.on-off-day") }}
                    {{ formatDateTime(od.outDateRaw) }} -
                    {{ od.outComment || "" }}
                  </li>
                  <li v-if="od.returnDate">
                    {{ $t("client.arrive") }}
                    {{ formatDateTime(od.returnDateRaw) }} -
                    {{ od.returnComment || "" }}
                  </li>
                </ul>
              </span>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- my reported canceled visits -->
    <v-row v-if="Object.keys(myReportedCanceledVisits).length > 0">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t("visits.todays-registred-cancelled-visits") }}
          </v-card-title>
          <v-card-text>
            <v-list-item
              dense
              v-for="(od, odId) in myReportedCanceledVisits"
              :key="odId"
            >
              <span>
                <span>
                  <strong>{{ od.clientName }}</strong>
                  {{ $t("visits.cancelled-planned-visit-at") }}
                  {{ od.hours }}:{{ od.minutes }}.{{ $t("general.comment") }}:
                  {{ od.comment }}
                </span>
              </span>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- clients -->
    <v-row>
      <template v-if="Object.keys(clientData).length > 0">
        <v-col cols="12">
          <h3>{{ $t("nav.clients") }}</h3>
        </v-col>

        <client-card
          v-for="(c, clId) in clientData"
          :key="clId"
          :client="c"
          :clientId="clId"
        >
        </client-card>
      </template>
      <template v-else>
        <v-card max-width="700" class="mx-auto" v-if="!loadingData">
          <v-card-text>
            <v-row v-if="Object.keys(userData).length > 0">
              <v-col cols="12" class="text-center">
                <h2>{{ $t("client.today-no-clients") }}</h2>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { db, auth } from "@/main";

import {
  formatDateInRiga,
  formatDateTimeInRiga,
  isoDateInRiga,
} from "@/helpers/dayjsDates.js";
import { incidents } from "@/helpers/constants";
import { monthKeyFromIso } from "@/helpers/helperFns.js";
import LoadingData from "@/components/utilities/LoadingData.vue";
import SelectDate from "@/components/utilities/SelectDate.vue";
import { find, includes, isEqual, isNil, split } from "lodash";
import ClientCard from "@/views/myclients/ClientCard.vue";
import ViewAsUserSelection from "@/components/ViewAsUserSelection.vue";
import { timestampToDate } from "@/helpers/dateTimeConvertingFunctions";

export default {
  name: "my-clients",
  components: { LoadingData, SelectDate, ClientCard, ViewAsUserSelection },
  data() {
    return {
      loadingData: false,
      clientData: {},
      formatDate: formatDateInRiga(new Date()),
      selectedDate: isoDateInRiga(new Date().toISOString().substr(0, 10)),
      myReportedIncidents: {},
      myReportedHealth: {},
      myReportedOffDays: {},
      myReportedCanceledVisits: {},
      userId: auth.currentUser.uid,
      userData: {},
      aiderServices: [],
      feedbackText: this.$t("global.loading-data"),
    };
  },

  async created() {
    this.loadData();
    // receive: from incident dialog
    this.$on("showHideFeedback", (showHide, message) => {
      this.feedbackText = message;
      this.loadingData = showHide;
    });

    this.$on("updateIncidents", () => {
      this.getMyReportedIncidents();
    });

    this.$on("updateHealth", () => {
      this.getMyReportedHealth();
    });

    this.$on("updateOffDay", (clientId, latestOffDay) => {
      this.clientData[clientId]["latestOffDay"] = latestOffDay;
      this.clientData = Object.assign({}, this.clientData);
      this.getMyReportedOffDays();
    });
    this.$on("updateCancelledVisits", () => {
      this.getMyReportedCanceledVisits();
    });
  },
  methods: {
    async onUserSelection(user) {
      const vm = this;
      if (!isNil(user)) {
        this.clientData = {};
        vm.userId = user.id;
        await this.loadData();
      }
    },
    async loadData() {
      let vm = this;
      //vm.userId = "hDaKPC2QTFeTiyaGyGYoA3dSVQe2"; //FOR TEST mok
      //vm.userId = "rUvbCUsqnNOiJXcXWTFG787Plbs1"; //FOR TEST bfh

      let getUserData = await db.collection("users").doc(vm.userId).get();

      if (getUserData.exists) {
        vm.userData = Object.assign({}, getUserData.data());
        vm.aiderServices = vm.userData.aiderServices
          ? vm.userData.aiderServices
          : [];
      }
      await this.getMyClients();
      this.getMyReportedIncidents();
      this.getMyReportedHealth();
      this.getMyReportedOffDays();
      this.getMyReportedCanceledVisits();
    },
    getTranslatedIncident(incident) {
      const foundIncident = find(
        incidents,
        (inc) =>
          !isNil(inc.value) && isEqual(inc.value.trim(), incident.incident)
      );
      return isNil(foundIncident)
        ? incident.incident
        : this.$t(foundIncident.text);
    },
    dateSelected(date) {
      this.selectedDate = date;
      this.getMyClients();
    },
    formatDateTime(sec) {
      const dateTime = timestampToDate(sec);
      return formatDateTimeInRiga(dateTime);
    },
    async getMyReportedOffDays() {
      let vm = this;
      let getOffDays = await db
        .collectionGroup("offDays")
        .where("lastAiderDate", "==", vm.formatDate)
        .where("lastAiderUid", "==", vm.userId)
        .orderBy("outAddedAt")
        .get();

      let myReportedOffDaysL = {};
      getOffDays.forEach((od) => {
        myReportedOffDaysL[od.id] = od.data();
      });
      vm.myReportedOffDays = Object.assign({}, myReportedOffDaysL);
    },
    async getMyReportedHealth() {
      let vm = this;
      let getHealth = await db
        .collection("bodyVitals")
        .where("createdAtFormatted", "==", vm.formatDate)
        .where("createdById", "==", vm.userId)
        .orderBy("createdAt")
        .get();

      let healthL = {};
      getHealth.forEach((h) => {
        healthL[h.id] = h.data();
      });
      vm.myReportedHealth = Object.assign({}, healthL);
    },
    async getMyReportedIncidents() {
      let vm = this;
      let getInc = await db
        .collection("incidents")
        .where("createdAtFormatted", "==", vm.formatDate)
        .where("createdBy", "==", vm.userId)
        .orderBy("createdAt")
        .get();

      let incL = {};
      getInc.forEach((i) => {
        incL[i.id] = i.data();
      });
      vm.myReportedIncidents = Object.assign({}, incL);
    },
    async getMyReportedCanceledVisits() {
      let vm = this;
      let getInc = await db
        .collection("canceledVisits")
        .where("createdAtFormatted", "==", vm.formatDate)
        .where("createdById", "==", vm.userId)
        .orderBy("createdAt")
        .get();

      let incL = {};
      getInc.forEach((i) => {
        incL[i.id] = i.data();
      });
      vm.myReportedCanceledVisits = Object.assign({}, incL);
    },
    async getMyClients() {
      if (this.aiderServices.indexOf("bfh") > -1) {
        await this.getMyBfhClients();
      }
      if (this.aiderServices.indexOf("neighborhood") > -1) {
        await this.getMyNbhClients("neighborhood");
      }
      if (this.aiderServices.indexOf("mokLit") > -1) {
        await this.getMyNbhClients("mokLit");
      }
    },
    async getMyNbhClients(serviceName) {
      let vm = this;
      this.loadingData = true;
      const monthKey = monthKeyFromIso(vm.selectedDate);

      const shiftsDocKey = `${serviceName}-${monthKey}`;
      let getShifts = await db
        .collection("shiftPlanning")
        .doc(shiftsDocKey)
        .get();

      let shifts = getShifts.exists ? getShifts.data() : {};

      let getClientsIds = [];
      let getClientsProm = [];
      Object.keys(shifts).forEach((shiftKey) => {
        let responsible = shifts[shiftKey];
        if (responsible === vm.userId) {
          const [clientId, yyyy, mm, dd, v] = shiftKey.split("-");
          const dateKey = `${yyyy}-${mm}-${dd}`;
          if (
            dateKey == vm.selectedDate &&
            getClientsIds.indexOf(clientId) === -1
          ) {
            getClientsIds.push(clientId);
            let getC = db.collection("clients").doc(clientId).get();
            getClientsProm.push(getC);
          }
        }
      });

      let values = await Promise.all(getClientsProm);

      values.forEach((doc) => {
        if (doc.exists) {
          const [year, month, date] = split(vm.selectedDate, "-");
          const newDate = new Date(year, month - 1, date, 12);
          const day = newDate.getDay();
          const data = doc.data();
          if (includes(data.nbhServiceDays, day)) {
            vm.clientData[doc.id] = data;
          }
        }
      });

      this.loadingData = false;
    },
    async getMyBfhClients() {
      let vm = this;
      this.loadingData = true;

      //get my tasks for today to then get my clients for today
      let getTasks = await db
        .collection("taskArchive")
        .where("lpTaskFormatDate", "==", vm.formatDate)
        .where("responsible", "==", vm.userId)
        .orderBy("clientName")
        .get();

      let getClients = [];
      let clientIds = [];
      //loop my tasks to get my clients
      getTasks.forEach((t) => {
        let td = t.data();
        let clId = td.clientId;

        if (clientIds.indexOf(clId) == -1 && td.service == "bfh") {
          //get client
          let getC = db.collection("clients").doc(clId).get();
          getClients.push(getC);
          clientIds.push(clId);
        }
      });

      let clientDocs = await Promise.all(getClients);
      clientDocs.forEach((doc) => {
        if (doc.exists) {
          vm.clientData[doc.id] = doc.data();
        }
      });
      this.loadingData = false;
    },
  },
};
</script>
