import {i18n} from '../language/i18n';

export const hourRules = [
  (v) => (!!v || v == 0) || i18n.t("form.required-hours-format"),
  (v) => v >= 0 || i18n.t("form.hours-format"),
  (v) => v  <= 23 || i18n.t("form.hours-format"),
];
export const minutesRules = [
  (v) => (!!v || v == 0)  || i18n.t("form.required-minutes-format"),
  (v) => v  >= 0 || i18n.t("form.minutes-format"),
  (v) => v  < 60 || i18n.t("form.minutes-format"),
];

export const required = [(v) => !!v ||  i18n.t("form.validate-required")];
